import { useState, useEffect } from 'react';
export default (function (queries, values, defaultValue) {
    // Array containing a media query list for each query
    var mediaQueryLists = typeof window !== "undefined" ? queries.map(function (q) {
        return window.matchMedia(q);
    }) : []; // Function that gets value based on matching media query
    var getValue = function getValue() {
        // Get index of first media query that matches
        var index = mediaQueryLists.findIndex(function (mql) {
            return mql.matches;
        }); // Return related value or defaultValue if none
        return (values === null || values === void 0 ? void 0 : values[index]) || defaultValue;
    }; // State and setter for matched value
    var _useState = useState(getValue), value = _useState[0], setValue = _useState[1];
    useEffect(function () {
        // Event listener callback
        // Note: By defining getValue outside of useEffect we ensure that it has
        // current values of hook args (as this hook callback is created once on mount).
        var handler = function handler() {
            return setValue(getValue);
        }; // Set a listener for each media query with above handler as callback.
        mediaQueryLists.forEach(function (mql) {
            return mql.addEventListener('change', handler);
        }); // Remove listeners on cleanup
        return function () {
            return mediaQueryLists.forEach(function (mql) {
                return mql.removeEventListener('change', handler);
            });
        };
    }, [] // Empty array ensures effect is only run on mount and unmount
    );
    return value;
});
