/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import Layout from './src/components/Layout'

export const wrapPageElement = ({ element, props }) => {
  if (process.env.NODE_ENV === 'development' && props.path.includes('resume')) {
    return element
  }
  return <Layout {...props}>{element}</Layout>
}
