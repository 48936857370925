import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";
function _templateObject() {
    var data = _taggedTemplateLiteralLoose(["\n  html,\n  body,\n  #___gatsby,\n  #gatsby-focus-wrapper {\n    margin: 0;\n    padding: 0;\n    height: 100%;\n    width: 100%;\n    font-family: 'Roboto Condensed', sans-serif;\n    display: flex;\n    overflow-y: auto;\n  }\n\n  a {\n    text-decoration: none;\n  }\n\n  * {\n    box-sizing: border-box;\n  }\n\n  p {\n    margin: 0 0 1rem;\n    line-height: ", ";\n  }\n\n  button {\n    border: none;\n    outline: none;\n    cursor: pointer;\n  }\n\n  textarea, input, button, select { \n    font-family: inherit; \n    font-size: inherit; \n  }\n"]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled, { createGlobalStyle, css } from 'styled-components';
import { Link } from 'gatsby';
import Image from 'gatsby-image/withIEPolyfill';
var blue = '#43419E';
var yellow = '#F8B529';
var white = '#F4F3F9';
var red = '#E44E2B';
var grey = "#888888";
var lineHeight = 1.5;
var boxShadow = "0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)";
export var mediaSizeBreakpoints = {
    sm: 30,
    md: 45,
    lg: 65,
    xl: 90
};
var mediaQueries = function mediaQueries(key) {
    return function (style) {
        return css(["@media (min-width:", "rem){", "}"], mediaSizeBreakpoints[key], style);
    };
};
var getPaddingOrMarginInRem = function getPaddingOrMarginInRem(value) {
    if (Array.isArray(value)) {
        return value.map(function (v) {
            return v === 0 ? '0' : v + 'rem';
        }).join(' ');
    }
    else {
        return value === 0 ? '0' : value + 'rem';
    }
};
export var GlobalStyle = createGlobalStyle(_templateObject(), lineHeight);
export var FlexContainer = styled.div.withConfig({
    displayName: "styles__FlexContainer",
    componentId: "k54sjz-0"
})(["display:flex;position:", ";width:", ";height:", ";padding:", ";margin:", ";flex-direction:", ";flex-wrap:", ";justify-content:", ";align-items:", ";align-content:", ";flex-grow:", ";flex-shrink:", ";flex-basis:", ";overflow:", ";z-index:", ";"], function (_ref) {
    var _ref$position = _ref.position, position = _ref$position === void 0 ? 'static' : _ref$position;
    return position;
}, function (_ref2) {
    var _ref2$w = _ref2.w, width = _ref2$w === void 0 ? 'auto' : _ref2$w;
    return width;
}, function (_ref3) {
    var _ref3$h = _ref3.h, height = _ref3$h === void 0 ? 'auto' : _ref3$h;
    return height;
}, function (_ref4) {
    var _ref4$p = _ref4.p, padding = _ref4$p === void 0 ? 0 : _ref4$p;
    return getPaddingOrMarginInRem(padding);
}, function (_ref5) {
    var _ref5$m = _ref5.m, margin = _ref5$m === void 0 ? 0 : _ref5$m;
    return getPaddingOrMarginInRem(margin);
}, function (_ref6) {
    var _ref6$flexDirection = _ref6.flexDirection, flexDirection = _ref6$flexDirection === void 0 ? 'row' : _ref6$flexDirection;
    return flexDirection;
}, function (_ref7) {
    var _ref7$flexWrap = _ref7.flexWrap, flexWrap = _ref7$flexWrap === void 0 ? 'nowrap' : _ref7$flexWrap;
    return flexWrap;
}, function (_ref8) {
    var _ref8$justifyContent = _ref8.justifyContent, justifyContent = _ref8$justifyContent === void 0 ? 'flex-start' : _ref8$justifyContent;
    return justifyContent;
}, function (_ref9) {
    var _ref9$alignItems = _ref9.alignItems, alignItems = _ref9$alignItems === void 0 ? 'stretch' : _ref9$alignItems;
    return alignItems;
}, function (_ref10) {
    var _ref10$alignContent = _ref10.alignContent, alignContent = _ref10$alignContent === void 0 ? 'stretch' : _ref10$alignContent;
    return alignContent;
}, function (_ref11) {
    var _ref11$flexGrow = _ref11.flexGrow, flexGrow = _ref11$flexGrow === void 0 ? 0 : _ref11$flexGrow;
    return flexGrow;
}, function (_ref12) {
    var _ref12$flexShrink = _ref12.flexShrink, flexShrink = _ref12$flexShrink === void 0 ? 1 : _ref12$flexShrink;
    return flexShrink;
}, function (_ref13) {
    var _ref13$flexBasis = _ref13.flexBasis, flexBasis = _ref13$flexBasis === void 0 ? 'auto' : _ref13$flexBasis;
    return flexBasis;
}, function (_ref14) {
    var _ref14$overFlow = _ref14.overFlow, overFlow = _ref14$overFlow === void 0 ? 'visible' : _ref14$overFlow;
    return overFlow;
}, function (_ref15) {
    var _ref15$zIndex = _ref15.zIndex, zIndex = _ref15$zIndex === void 0 ? 'auto' : _ref15$zIndex;
    return zIndex;
});
export var Logo = styled(Image).withConfig({
    displayName: "styles__Logo",
    componentId: "k54sjz-1"
})(["margin:0 10px;flex-shrink:0;"]);
export var Header = styled.header.withConfig({
    displayName: "styles__Header",
    componentId: "k54sjz-2"
})(["position:fixed;top:0;left:0;display:flex;width:100%;height:80px;background-color:", ";align-items:center;justify-content:space-between;padding-left:1rem;padding-right:4rem;z-index:10;box-shadow:", ";", " ", " ", "{margin:0;width:80px !important;height:80px !important;}"], blue, boxShadow, mediaQueries('md')(css(["padding-left:2rem;padding-right:5rem;"])), mediaQueries('lg')(css(["display:none;"])), Logo);
export var Aside = styled.aside.withConfig({
    displayName: "styles__Aside",
    componentId: "k54sjz-3"
})(["position:fixed;left:0;padding-top:1rem;display:flex;flex-direction:column;width:200px;height:100%;background-color:", ";z-index:10;box-shadow:", ";"], blue, boxShadow);
export var NavWrapper = styled.div.withConfig({
    displayName: "styles__NavWrapper",
    componentId: "k54sjz-4"
})(["position:fixed;display:flex;flex-direction:column;justify-content:space-evenly;align-items:center;font-size:2rem;background-color:", ";color:", ";position:absolute;top:16px;right:1rem;z-index:10;box-shadow:", ";max-height:calc(100vh - 16px);overflow-y:auto;", ""], red, white, boxShadow, mediaQueries('md')(css(["right:2rem;"])));
export var NavToggleButton = styled.button.withConfig({
    displayName: "styles__NavToggleButton",
    componentId: "k54sjz-5"
})(["position:absolute;top:0;right:0;width:48px;height:48px;background-color:transparent;"]);
export var NavWrapperBar = styled.div.withConfig({
    displayName: "styles__NavWrapperBar",
    componentId: "k54sjz-6"
})(["position:absolute;width:0;height:4px;background-color:", ";"], white);
export var Nav = styled.nav.withConfig({
    displayName: "styles__Nav",
    componentId: "k54sjz-7"
})(["margin:0.5rem 0;width:200px;position:relative;overflow:auto;", ""], mediaQueries('lg')(css(["flex-grow:1;"])));
export var NavItem = styled(Link).withConfig({
    displayName: "styles__NavItem",
    componentId: "k54sjz-8"
})(["font-size:1rem;display:block;padding:1rem;color:", ";margin:0 1rem;border-bottom:1px solid ", ";text-transform:uppercase;&[data-current='true']{pointer-events:none;color:", ";font-weight:700;}&:hover{color:", ";}"], white, white, yellow, yellow);
export var SocialLink = styled.a.withConfig({
    displayName: "styles__SocialLink",
    componentId: "k54sjz-9"
})(["display:flex;align-items:center;margin:0.5rem;font-size:1.5rem;color:", ";&:hover{color:", ";}"], white, yellow);
export var Copyright = styled.div.withConfig({
    displayName: "styles__Copyright",
    componentId: "k54sjz-10"
})(["font-size:0.85rem;font-weight:400;color:", ";text-transform:uppercase;text-align:center;padding:0.5rem;"], white);
export var Main = styled.main.withConfig({
    displayName: "styles__Main",
    componentId: "k54sjz-11"
})(["position:relative;width:100%;height:calc(100% - 80px);top:80px;overflow-x:hidden;overflow-y:auto;", ""], mediaQueries('lg')(css(["width:calc(100% - 200px);height:100%;top:0;left:200px;"])));
export var BackgroundImage = styled(Image).withConfig({
    displayName: "styles__BackgroundImage",
    componentId: "k54sjz-12"
})(["position:fixed !important;top:0;right:0;width:100%;height:100%;z-index:-1;"]);
export var PageContent = styled.div.withConfig({
    displayName: "styles__PageContent",
    componentId: "k54sjz-13"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;width:100%;min-height:calc(100vh - 80px);padding:1rem 0;opacity:0;", " ", ""], mediaQueries('md')(css(["padding:1rem;"])), mediaQueries('lg')(css(["min-height:100vh;"])));
export var PageContainer = styled.div.withConfig({
    displayName: "styles__PageContainer",
    componentId: "k54sjz-14"
})(["position:absolute;width:100%;min-height:100%;overflow-y:hidden;background:rgb(237,238,240);background:linear-gradient( 30deg,rgba(237,238,240,1) 0%,rgba(221,228,238,0.8) 75%,rgba(192,204,230,0.75) 100% );", "{opacity:inherit;}", ""], PageContent, mediaQueries('lg')(css(["width:75%;max-width:1200px;min-width:820px;"])));
export var Title = styled.h2.withConfig({
    displayName: "styles__Title",
    componentId: "k54sjz-15"
})(["font-size:2rem;font-weight:700;position:relative;margin-bottom:0;text-transform:uppercase;", " &::before{content:'';display:inline-block;width:60px;height:15px;border-color:", ";border-style:solid;border-width:5px 5px 0 5px;position:absolute;top:-20px;left:0;right:0;margin:auto;box-sizing:border-box;}"], mediaQueries('md')(css(["font-size:2.5rem;"])), red);
export var SubTitle = styled.h4.withConfig({
    displayName: "styles__SubTitle",
    componentId: "k54sjz-16"
})(["font-size:1rem;font-weight:400;text-transform:uppercase;padding:0.5rem;margin-top:0;margin-bottom:1rem;text-align:center;&::after{content:'';display:block;border-bottom:5px solid ", ";width:60px;margin-left:auto;margin-right:auto;padding-top:10px;box-sizing:border-box;}"], red);
export var Portrait = styled.div.withConfig({
    displayName: "styles__Portrait",
    componentId: "k54sjz-17"
})(["margin-top:1rem;display:flex;align-items:center;justify-content:center;padding:0.5rem;border:0.5rem solid ", ";border-radius:100%;"], red);
export var Greeting = styled.h1.withConfig({
    displayName: "styles__Greeting",
    componentId: "k54sjz-18"
})(["font-size:2rem;font-weight:700;padding:1rem;margin:1rem 0 0;text-transform:uppercase;text-align:center;", ""], mediaQueries('md')(css(["font-size:4rem;"])));
export var Profession = styled.h3.withConfig({
    displayName: "styles__Profession",
    componentId: "k54sjz-19"
})(["font-size:1rem;font-weight:400;padding:1rem;border-top:0.2rem solid ", ";border-bottom:0.2rem solid ", ";text-transform:uppercase;text-align:center;", ""], red, red, mediaQueries('md')(css(["font-size:1.5rem;"])));
export var buttonStyle = css(["min-width:150px;text-transform:uppercase;background-color:", ";color:", ";margin:0.75rem;padding:0.75rem;font-size:1rem;border:2px solid transparent;box-shadow:", ";border-radius:0.25rem;transition:all 0.1s linear;", " &:hover{background-color:", ";color:", ";font-weight:700;}&:active{font-weight:700;transform:scale(0.95);}"], red, white, boxShadow, mediaQueries('md')(css(["min-width:180px;font-size:1.2rem;"])), yellow, blue);
export var Button = styled.button.withConfig({
    displayName: "styles__Button",
    componentId: "k54sjz-20"
})(["", ""], buttonStyle);
export var LinkButton = styled.a.withConfig({
    displayName: "styles__LinkButton",
    componentId: "k54sjz-21"
})(["appearance:button;text-align:center;", ""], buttonStyle);
export var GatsbyLinkButton = styled(Link).withConfig({
    displayName: "styles__GatsbyLinkButton",
    componentId: "k54sjz-22"
})(["appearance:button;text-align:center;", ""], buttonStyle);
export var Section = styled.section.withConfig({
    displayName: "styles__Section",
    componentId: "k54sjz-23"
})(["width:", ";min-width:calc(100% - 1rem);display:flex;flex-direction:column;position:relative;background-color:", ";padding:1rem;margin:0 0.5rem 0.5rem;border-radius:0 40px 0 40px;flex:1 0 auto;&::before,&::after{content:' ';width:40px;height:40px;position:absolute;border-style:solid;border-width:5px;box-sizing:border-box;}&::before{left:0;top:0;border-color:", " transparent transparent ", ";}&::after{right:0;bottom:0;border-color:transparent ", " ", " transparent;}", ""], function (_ref16) {
    var _ref16$w = _ref16.w, width = _ref16$w === void 0 ? 'auto' : _ref16$w;
    return width;
}, white, red, red, red, red, mediaQueries('md')(css(["margin:0.5rem;min-width:400px;"])));
export var SectionTitle = styled.h5.withConfig({
    displayName: "styles__SectionTitle",
    componentId: "k54sjz-24"
})(["font-size:1.2rem;font-weight:700;margin:0.5rem 0;display:flex;align-items:center;text-transform:capitalize;svg{margin-right:0.5rem;color:", ";font-size:2rem;flex-shrink:0;}"], blue);
export var Location = styled.span.withConfig({
    displayName: "styles__Location",
    componentId: "k54sjz-25"
})(["font-size:0.9rem;color:", ";margin-bottom:0.5rem;"], grey);
export var Award = styled.div.withConfig({
    displayName: "styles__Award",
    componentId: "k54sjz-26"
})(["display:flex;justify-content:space-between;align-items:flex-start;padding:0.5rem 0.25rem;&:not(:last-child){border-bottom:1px dashed ", ";}span{font-size:0.9rem;margin-right:0.5rem;}"], grey);
export var CarouselButton = styled.button.withConfig({
    displayName: "styles__CarouselButton",
    componentId: "k54sjz-27"
})(["width:", ";height:8px;background-color:", ";margin:2px;border:none;border-radius:15px;transition:all 0.3s ease-in-out;"], function (_ref17) {
    var isActive = _ref17.isActive;
    return isActive ? '25px' : '15px';
}, function (_ref18) {
    var isActive = _ref18.isActive;
    return isActive ? blue : grey;
});
export var CarouselArrow = styled.button.withConfig({
    displayName: "styles__CarouselArrow",
    componentId: "k54sjz-28"
})(["margin:0.5rem;width:2.5rem;height:2.5rem;background-color:black;display:flex;justify-content:center;align-items:center;font-size:2em;color:", ";border-radius:100%;opacity:", ";cursor:", ";z-index:1;&:hover{opacity:", ";}"], white, function (_ref19) {
    var isVisible = _ref19.isVisible;
    return isVisible ? 0.5 : 0;
}, function (_ref20) {
    var isVisible = _ref20.isVisible;
    return isVisible ? 'pointer' : 'default';
}, function (_ref21) {
    var isVisible = _ref21.isVisible;
    return isVisible ? 0.8 : 0;
});
export var TimelineBlock = styled.div.withConfig({
    displayName: "styles__TimelineBlock",
    componentId: "k54sjz-29"
})(["width:100%;display:flex;position:relative;min-height:225px;", ""], mediaQueries('md')(css(["&::before{content:'';position:absolute;width:10%;height:5px;background-color:", ";left:40%;top:40px;}&::after{content:'';position:absolute;width:5px;height:100%;background-color:", ";left:50%;transform:translateX(-50%);}&:not(:first-child){margin-top:-5rem;}&:nth-child(even){flex-direction:row-reverse;&::before{left:50%;}}&:last-child{&::after{width:0;height:0;}}"], red, red)));
export var TimelineContent = styled.div.withConfig({
    displayName: "styles__TimelineContent",
    componentId: "k54sjz-30"
})(["background-color:", ";padding:0.5rem;margin:0.5rem;width:100%;display:flex;flex-direction:column;align-items:flex-start;border-left:5px solid ", ";z-index:1;box-shadow:", ";header{min-height:80px;padding-right:80px;h5{margin:0.5rem 0;font-size:1.2rem;font-weight:700;}small{display:block;}}", " small{margin:0.5rem 0;font-size:0.9rem;}svg{cursor:pointer;align-self:center;font-size:1.2rem;color:", ";margin-top:0.5rem;}"], white, red, boxShadow, mediaQueries('md')(css(["width:40%;padding:1rem;header{padding:0;}"])), blue);
export var TimelineContentText = styled.div.withConfig({
    displayName: "styles__TimelineContentText",
    componentId: "k54sjz-31"
})(["height:", "rem;overflow:hidden;"], lineHeight * 5);
export var TimelineIcon = styled.div.withConfig({
    displayName: "styles__TimelineIcon",
    componentId: "k54sjz-32"
})(["width:80px;height:80px;display:flex;justify-content:center;align-items:center;background-color:", ";border-radius:100%;padding:0.5rem;position:absolute;top:1rem;right:1rem;box-shadow:", ";z-index:2;", ""], white, boxShadow, mediaQueries('md')(css(["top:0;left:50%;transform:translateX(-50%);"])));
export var ProjectsContainer = styled.div.withConfig({
    displayName: "styles__ProjectsContainer",
    componentId: "k54sjz-33"
})(["width:100%;display:flex;flex-direction:column;margin:0.5rem;box-shadow:", ";"], boxShadow);
export var ProjectImages = styled.div.withConfig({
    displayName: "styles__ProjectImages",
    componentId: "k54sjz-34"
})(["max-height:400px;display:flex;justify-content:center;align-items:center;overflow:hidden;", ""], mediaQueries('md')(css(["width:50%;"])));
export var ProjectContent = styled.div.withConfig({
    displayName: "styles__ProjectContent",
    componentId: "k54sjz-35"
})(["display:flex;flex-direction:column;height:100%;justify-content:center;padding:1rem;", " header{margin-bottom:1rem;font-size:1.2rem;font-weight:700;}footer{display:flex;}"], mediaQueries('md')(css(["width:50%;"])));
export var ProjectLink = styled.a.withConfig({
    displayName: "styles__ProjectLink",
    componentId: "k54sjz-36"
})(["font-size:1.5rem;margin:0 0.5rem;color:", ";&:hover{color:", ";}"], red, yellow);
export var ProjectDescription = styled.div.withConfig({
    displayName: "styles__ProjectDescription",
    componentId: "k54sjz-37"
})(["height:", "rem;overflow:hidden;"], lineHeight * 4);
export var ProjectReadMore = styled.button.withConfig({
    displayName: "styles__ProjectReadMore",
    componentId: "k54sjz-38"
})(["background-color:transparent;margin:1rem 0;padding:0;color:", ";font-weight:700;align-self:flex-start;text-transform:uppercase;"], blue);
export var ProjectBlock = styled.div.withConfig({
    displayName: "styles__ProjectBlock",
    componentId: "k54sjz-39"
})(["display:flex;flex-direction:column;background-color:", ";", ""], white, mediaQueries('md')(css(["height:25rem;flex-direction:row;&:nth-child(even){flex-direction:row-reverse;", "{header{text-align:right;}", "{align-self:flex-end;}footer{flex-direction:row-reverse;}}}"], ProjectContent, ProjectReadMore)));
export var ModalWrapper = styled.div.withConfig({
    displayName: "styles__ModalWrapper",
    componentId: "k54sjz-40"
})(["position:fixed;width:100vw;height:100vh;top:0;left:0;background-color:rgba(0,0,0,0.5);z-index:100;"]);
export var ModalContainer = styled.div.withConfig({
    displayName: "styles__ModalContainer",
    componentId: "k54sjz-41"
})(["position:fixed;background-color:", ";z-index:200;box-shadow:", ";display:flex;flex-direction:column;"], white, boxShadow);
export var ModalHeader = styled.header.withConfig({
    displayName: "styles__ModalHeader",
    componentId: "k54sjz-42"
})(["position:relative;padding:1rem 3rem 1rem 1rem;border-bottom:1px solid ", ";h5{font-size:1.2rem;font-weight:700;margin:0;}svg{position:absolute;top:1rem;right:1rem;font-size:1rem;color:", ";cursor:pointer;flex-shrink:0;&:hover{color:", ";}}"], grey, grey, blue);
export var ModalContent = styled.div.withConfig({
    displayName: "styles__ModalContent",
    componentId: "k54sjz-43"
})(["overflow:auto;padding:1rem;flex-grow:1;"]);
export var ModalFooter = styled.footer.withConfig({
    displayName: "styles__ModalFooter",
    componentId: "k54sjz-44"
})(["display:flex;padding:1rem;border-top:1px solid ", ";flex-direction:row-reverse;"], grey);
export var MailIcon = styled.div.withConfig({
    displayName: "styles__MailIcon",
    componentId: "k54sjz-45"
})(["position:absolute;display:flex;justify-content:center;align-items:center;font-size:4rem;color:", ";height:100px;width:100px;top:-50px;left:50%;transform:translateX(-50%);border-radius:100%;background-color:", ";box-shadow:", ";&::after{content:' ';position:absolute;width:120px;height:60px;top:50px;left:-10px;background-color:", ";}svg{z-index:1;}"], red, white, boxShadow, white);
export var Form = styled.form.withConfig({
    displayName: "styles__Form",
    componentId: "k54sjz-46"
})(["margin-top:60px;position:relative;padding:3rem 0.5rem 1rem;display:flex;flex-wrap:wrap;max-width:800px;width:100%;background-color:", ";box-shadow:", ";"], white, boxShadow);
export var FormGroup = styled.div.withConfig({
    displayName: "styles__FormGroup",
    componentId: "k54sjz-47"
})(["width:100%;position:relative;padding:0.5rem;flex-shrink:0;flex-grow:1;", ""], mediaQueries('sm')(css(["min-width:300px;width:50%;"])));
export var Input = styled.input.withConfig({
    displayName: "styles__Input",
    componentId: "k54sjz-48"
})(["display:block;background-color:", ";padding:0.5rem 0;width:100%;border:none;border-radius:0;border-bottom:1px solid ", ";margin:0.5rem 0;&:focus{outline:none;}&:focus ~ label,&:not([value='']) ~ label{color:black;top:0;font-size:0.8rem;}"], white, grey);
export var TextArea = styled.textarea.withConfig({
    displayName: "styles__TextArea",
    componentId: "k54sjz-49"
})(["display:block;background-color:", ";padding:0.5rem 0;width:100%;border:none;border-radius:0;border-bottom:1px solid ", ";margin:0.5rem 0;resize:none;&:focus{outline:none;}&:focus ~ label,&:not(:empty) ~ label{color:black;top:0;font-size:0.9rem;}"], white, grey);
export var Label = styled.label.withConfig({
    displayName: "styles__Label",
    componentId: "k54sjz-50"
})(["font-size:1rem;position:absolute;pointer-events:none;left:0.5rem;top:1.5rem;transition:300ms ease all;font-weight:700;color:", ";;"], grey);
export var ToastMessage = styled.div.withConfig({
    displayName: "styles__ToastMessage",
    componentId: "k54sjz-51"
})(["display:grid;grid-template-columns:auto 1fr;grid-gap:0.5rem;padding:0.5rem;p{margin:0;}"]);
